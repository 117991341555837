export const FETCH_AGENTS_REQUESTED = 'AGENTS/FETCH_AGENTS_REQUESTED';
export const FETCH_AGENTS_SUCCESS = 'AGENTS/FETCH_AGENTS_SUCCESS';
export const FETCH_AGENTS_FAILURE = 'AGENTS/FETCH_AGENTS_FAILURE';

export const LEND_TO_AGENT_REQUESTED = 'AGENTS/LEND_TO_AGENT_REQUESTED';
export const LEND_TO_AGENT_SUCCESS = 'AGENTS/LEND_TO_AGENT_SUCCESS';
export const LEND_TO_AGENT_FAILURE = 'AGENTS/LEND_TO_AGENT_FAILURE';

export const CLEAR_AGENTS_NOTIFCATION = 'AGENTS/CLEAR_AGENTS_NOTIFCATION';

export const ASSIGN_AGENTS_REQUESTED = 'AGENTS/ASSIGN_AGENTS_REQUESTED';
export const ASSIGN_AGENTS_SUCCESS = 'AGENTS/ASSIGN_AGENTS_SUCCESS';
export const ASSIGN_AGENTS_FAILURE = 'AGENTS/ASSIGN_AGENTS_FAILURE';

export const FETCH_PLOTS_AND_CONCERNS_REQUESTED = 'AGENTS/FETCH_PLOTS_AND_CONCERNS_REQUESTED';
export const FETCH_PLOTS_AND_CONCERNS_SUCCESS = 'AGENTS/FETCH_PLOTS_AND_CONCERNS_SUCCESS';
export const FETCH_PLOTS_AND_CONCERNS_FAILURE = 'AGENTS/FETCH_PLOTS_AND_CONCERNS_FAILURE';

export const BEGIN_PLOT_REQUESTED = 'AGENTS/BEGIN_PLOT_REQUESTED';
export const BEGIN_PLOT_SUCCESS = 'AGENTS/BEGIN_PLOT_SUCCESS';
export const BEGIN_PLOT_FAILURE = 'AGENTS/BEGIN_PLOT_FAILURE';

export const HEAR_REPORT_REQUESTED = 'AGENTS/HEAR_REPORT_REQUESTED';
export const HEAR_REPORT_SUCCESS = 'AGENTS/HEAR_REPORT_SUCCESS';
export const HEAR_REPORT_FAILURE = 'AGENTS/HEAR_REPORT_FAILURE';

export const CHOOSE_REPORT_BRANCH_REQUESTED = 'AGENTS/CHOOSE_REPORT_BRANCH_REQUESTED';
export const CHOOSE_REPORT_BRANCH_SUCCESS = 'AGENTS/CHOOSE_REPORT_BRANCH_SUCCESS';
export const CHOOSE_REPORT_BRANCH_FAILURE = 'AGENTS/CHOOSE_REPORT_BRANCH_FAILURE';

export const CLEAR_REPORT_NOTIFCATION = 'AGENTS/CLEAR_REPORT_NOTIFCATION';
