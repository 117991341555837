import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useGoogleLogin,
} from '@react-oauth/google';

import classnames from 'classnames';

import {
  unlinkSocialAccount,
} from 'actions/settings';
import fetchAuthMethods from 'actions/settings/fetchAuthMethods';
import {
  linkGoogle,
} from 'actions/settings/linkSocialAccount';

import Loading from 'components/Loading';

import {
  useAppSelector,
} from 'features/app/store';

import {
  Success,
} from 'services/BaseMonadicService';

export default function GoogleAuthComponent({
  inverse,
  onLinkFailure,
  onUnlinkFailure,
  onLinkSuccess,
}: Props) {
  const dispatch = useDispatch();
  const authMethods = useAppSelector(state => state.settings.authMethods);

  const hasGoogleAuth = useMemo(() => !!(authMethods?.find(m => m.type === 'Google')), [authMethods]);

  const [isLinking, setIsLinking] = useState(false);
  const [isUnlinking, setIsUnlinking] = useState(false);

  const onClickToUnlink = useCallback(async () => {
    setIsUnlinking(true);

    const result = await unlinkSocialAccount('google')(dispatch);

    await fetchAuthMethods()(dispatch);

    if (!(result instanceof Success)) {
      onUnlinkFailure?.(result.message);
    }

    setIsUnlinking(false);
  }, [
    dispatch,
    onUnlinkFailure,
  ]);

  const onLoginSuccess = useCallback(async (authResponse) => {
    if (authResponse) {
      setIsLinking(true);

      const request = {
        token: authResponse.access_token,
      };

      const result = await linkGoogle(request)(dispatch);

      if (result instanceof Success) {
        onLinkSuccess?.();

        await fetchAuthMethods()(dispatch);
      } else {
        onLinkFailure?.(result.message);
      }

      setIsLinking(false);
    }
  }, [
    dispatch,
    onLinkFailure,
    onLinkSuccess,
  ]);

  const onLoginFailure = useCallback((..._args) => {
    // TODO: handle Google auth failure gracefully.
    // This is called when the user does not authenticate with Google (not if linking fails)
  }, [
  ]);

  const doGoogleAuth = useGoogleLogin({
    onSuccess: onLoginSuccess,
    onError: onLoginFailure,
  });

  if (isLinking || isUnlinking) {
    return (
      <div
        style={{
          display: 'flex',
          marginLeft: '3px',
        }}
      >
        <Loading
          spinner
          small
        />
      </div>
    );
  }

  if (hasGoogleAuth) {
    return (<>
      <i className='fa fa-fw fa-google' />
      {' '}
      <button
        onClick={onClickToUnlink}
        type='button'
        className={classnames(
          'button--link',
          inverse && 'button--link-inverse',
        )}
      >
        Unlink Google
      </button>
    </>);
  }

  return (<>
    <i className='fa fa-fw fa-google' />
    {' '}
    <button
      className={classnames(
        'button--link',
        inverse && 'button--link-inverse',
      )}
      onClick={() => doGoogleAuth()}
      type='button'
    >
      Link Google to this account
    </button>
  </>);
}

type Props = {
  inverse?: boolean,
  onLinkFailure?: (message: string) => void,
  onLinkSuccess?: () => void,
  onUnlinkFailure?: (message: string) => void,
};
